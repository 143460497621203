import React, {useState} from 'react';
import './Signup.css';
import {Link} from "react-router-dom";
import signupImage from '../Assets/newlogin.jpg';
import {useNavigate} from 'react-router-dom';
import {FaEye, FaEyeSlash} from "react-icons/fa"; // Make sure to import CustomAlert

function Signup() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [errorMessage, setErrorMessage] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const isOtpComplete = otp.every(val => val.trim() !== "");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [sect, setSect] = useState('');
    const handleSectChange = (event) => {
        setSect(event.target.value);
    };


    const [passwordShown, setPasswordShown] = useState(false);  // State for showing/hiding password
    const [newPasswordShown, setNewPasswordShown] = useState(false);  // State for showing/hiding new password in reset form

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false; // Ensure input is numeric
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Auto-focus to next input
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    const requestOtp = async () => {
        if (!validateEmail(email)) {
            setIsEmailValid(false);
            return;
        } else {
            setIsEmailValid(true);
        }
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`https://api.digitalaalim.in/signup/request-otp/?email=${email}`, {
                method: 'POST', headers: {
                    'Origin': 'https://chat.digitalaalim.in/'
                },
            });

            setIsLoading(false);
            if (response.ok) {
                setShowOtpInput(true);
                alert("OTP sent to your email. Please verify to continue signup.");
            } else {
                alert(`Request OTP failed: Invalid email or User already Exists`);
            }
        } catch (error) {
            alert('Request OTP error:', error);
            setIsLoading(false);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isOtpComplete) {
            alert('Please fill all the OTP fields.');
            setIsLoading(false);
            return;
        }
        const otpString = otp.join("");  // Join the OTP array into a single string
        try {
            const response = await fetch('https://api.digitalaalim.in/signup/madrasah-admin', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://chat.digitalaalim.in/'
                }, body: JSON.stringify({name, email, sect, otp: otpString, password}),
            });

            if (!response.ok) {
                alert(`Signup failed`);
                //setTimeout(() => navigate('/'), 5000); // Navigate after alert
            }

            const data = await response.json();
            localStorage.setItem('accessToken', data.access_token);
            setIsLoading(false);
            alert('Signup successful.');
            await initializeChatbot(navigate);
            navigate('/Dashboard');


        } catch (error) {
            console.error('Signup error:', error);
            setIsLoading(false);
            alert('Signup error: ' + error.message);
        }
    };


    const initializeChatbot = async (navigate) => {
        try {
            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken) {
                throw new Error("No access token found. Please login or sign up.");
            }

            const initResponse = await fetch('https://api.digitalaalim.in/initialize_model/', {
                method: 'POST', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Origin': 'https://chat.digitalaalim.in/'
                },
            });

            if (!initResponse.ok) {
                throw new Error(`Failed to initialize the model: ${initResponse.statusText}`);
            }

        } catch (error) {
            console.error(error);
        }
    };
    return (<div className="signup-container">
        <div className="signup-card">
            <div className="signup-image-container">
                <img src={signupImage} alt="Signup" className="signup-image"/>
            </div>
            {!showOtpInput ? (<form className="signup-form" onSubmit={(e) => {
                e.preventDefault();
                requestOtp();
            }}>
                <h1>Create New Account</h1>
                <input type="text" placeholder="Name" required value={name}
                       onChange={(e) => setName(e.target.value)}/>
                <input
                    type="email"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={!isEmailValid ? "invalid" : ""}
                />
                {!isEmailValid && <p className="error-message">Please enter a valid email address</p>}
                <div className="signup-action-container">
                    <button type="submit" className="signup-btn" disabled={isLoading}>Request OTP</button>
                    {isLoading && <div className="loader"></div>}
                </div>
                <p className="login-message">
                    Already have an account? <Link to="/">Log in</Link>
                </p>
            </form>) : (<form className="signup-form" onSubmit={handleOtpSubmit}>
                <h1>Enter OTP</h1>
                <div className="otp-container">
                    {otp.map((_, index) => (<input
                        key={index}
                        type="text"
                        className="otp-input"
                        maxLength="1"
                        value={otp[index] || ""}
                        onChange={e => handleOtpChange(e.target, index)}
                    />))}
                </div>
                <select value={sect} onChange={handleSectChange} className="sect-dropdown">
                    <option value="">Select Sect</option>
                    <option value="Hanafi">Hanafi</option>
                    <option value="Jafari">Jafari</option>
                    <option value="Hanbali">Hanbali</option>
                    <option value="Shafi">Shafi</option>
                    <option value="Malaki">Malaki</option>
                </select>
                <div className="password-field">
                    <input
                        type={passwordShown ? 'text' : 'password'}
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <i onClick={togglePasswordVisibility}
                       style={{position: 'absolute', right: '15px', top: '49%', transform: 'translateY(-50%)'}}>
                        {passwordShown ? <FaEye/> : <FaEyeSlash/>}
                    </i>
                </div>
                <div className="password-field">
                    <input
                        type={newPasswordShown ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <i onClick={toggleNewPasswordVisibility}
                       style={{position: 'absolute', right: '15px', top: '49%', transform: 'translateY(-50%)'}}>
                        {newPasswordShown ? <FaEye/> : <FaEyeSlash/>}
                    </i>
                </div>
                <div className="signup-action-container">
                    <button type="submit" className="signup-btn" disabled={isLoading}>Verify and Register
                    </button>
                    {isLoading && <div className="loader"></div>}
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>)

            }
        </div>
    </div>);
}

export default Signup;
