import React, {useState} from 'react';
import './Dashboard.css';
import {useNavigate} from 'react-router-dom'; // Import useHistory hook from React Router
import {useRef} from 'react';
import {useEffect} from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {FaTrash} from 'react-icons/fa';

function Dashboard() {
    const [link, setLink] = useState('');
    const [botName, setBotName] = useState('');
    const [activeSection, setActiveSection] = useState('home');
    const fileInputRef = useRef(null);
    const formData = new FormData();
    const [bots, setBots] = useState([]);
    const [fileInputs, setFileInputs] = useState({});
    const [linkInputs, setLinkInputs] = useState({});
    const [isLoading, setIsLoading] = useState({});
    const [isDeployLoading, setIsDeployLoading] = useState(false);
    const [isFeedbackLoading, setIsisFeedbackLoading] = useState(false);
    const [isSettingLoading, setIsSettingLoading] = useState(false);


    const [icon, setIcon] = useState(null);
    const [instruction, setInstruction] = useState('');
    const [iconPreview, setIconPreview] = useState(null);

    const allowedFileTypes = ['application/pdf', 'text/csv',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain'];


    const handleIconChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setIconPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setIcon(file);  // Update the icon file state as well
    };

    const handleCancelUpload = () => {
        setIcon(null);
        setIconPreview(null);
    };


    const handleInstructionChange = (event) => {
        setInstruction(event.target.value);
    };


    const handleFileChange = (event, botId) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => allowedFileTypes.includes(file.type));

        if (validFiles.length !== files.length) {
            alert('Only PDF, CSV, DOCX, and TXT files are allowed.');
        }

        setFileInputs({
            ...fileInputs,
            [botId]: validFiles
        });
    };


    const handleLinkChange = (event, botId) => {
        setLinkInputs({
            ...linkInputs,
            [botId]: event.target.value
        });
    };

    const handleFileDelete = (botId, fileIndex) => {
        const updatedFiles = [...fileInputs[botId]];
        updatedFiles.splice(fileIndex, 1);
        setFileInputs({
            ...fileInputs,
            [botId]: updatedFiles
        });
    };

    const updateBot = async (botId) => {
        const accessToken = localStorage.getItem('accessToken');
        setIsLoading(prevState => ({...prevState, [botId]: true}));

        const formData = new FormData();
        if (fileInputs[botId]) {
            fileInputs[botId].forEach(file => {
                formData.append('files', file);
            });
        }
        if (linkInputs[botId]) {
            formData.append('links', linkInputs[botId]);
        }
        formData.append('bot_id', botId);

        try {
            const response = await fetch('https://api.digitalaalim.in/madrasah_update_model', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            });
            if (response.ok) {
                alert('Bot updated successfully');
            } else {
                console.error('Failed to update bot');
            }
        } catch (error) {
            console.error('Error updating bot:', error);
        } finally {
            setIsLoading(prevState => ({...prevState, [botId]: false}));
        }
    };

    const deleteBot = async (botId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/madrasah/bots/${botId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                setBots(bots.filter(bot => bot.bot_id !== botId));
                alert('Bot deleted successfully');
            } else {
                console.error('Failed to delete bot');
            }
        } catch (error) {
            console.error('Error deleting bot:', error);
        }
    };


    const handleBotNameChange = (event) => {
        setBotName(event.target.value);
    };


    const [botDescription, setBotDescription] = useState(''); // State to hold bot description

    const deployBot = async () => {
        const accessToken = localStorage.getItem('accessToken');
        setIsDeployLoading(true);

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file);
        });
        formData.append('links', link);
        formData.append('bot_name', botName);
        formData.append('bot_description', botDescription); // Add bot description to FormData
        if (icon) {
            formData.append('icon', icon);
        }
        formData.append('instruction', instruction);


        try {
            const response = await fetch('https://api.digitalaalim.in/deploy_madrasah_bot/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            });

            const data = await response.json();
            if (response.ok) {
                setIsDeployLoading(false);
                alert('Bot deployed successfully');
            } else {
                console.error('Failed to deploy bot:', data.message);
            }
        } catch (error) {
            console.error('Error deploying bot:', error);
        }
    };


    // Password Update

    const [selectedFiles, setSelectedFiles] = useState([]);


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const submitFeedback = async () => {
        const accessToken = localStorage.getItem('accessToken');
        setIsisFeedbackLoading(true);

        const feedback = {
            name,
            email,
            message,
        };

        try {
            const response = await fetch('https://api.digitalaalim.in/submit_feedback/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(feedback),
            });

            if (response.ok) {
                setIsisFeedbackLoading(false);
                alert('Feedback submitted successfully');
                setName('');
                setEmail('');
                setMessage('');
            } else {
                console.error('Failed to submit feedback');
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };


    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

        const fetchBots = async () => {
            try {
                const response = await fetch('https://api.digitalaalim.in/madrasah/bots/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setBots(data);
                } else {
                    console.error('Failed to fetch bots');
                }
            } catch (error) {
                console.error('Error fetching bots:', error);
            }
        };

        fetchBots();

    }, []);


    const handleDeployLinkChange = (event) => {
        setLink(event.target.value);
    };


    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/');
    };


    if (fileInputRef.current && fileInputRef.current.files) {
        Array.from(fileInputRef.current.files).forEach(file => {
            formData.append('files', file);
        });
    }
    let navigate = useNavigate(); // Initialize the useNavigate hook


    // const handleUpdateFileChange = (event) => {
    //     setSelectedFiles(Array.from(event.target.files));
    // };

    const handleUpdateFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => allowedFileTypes.includes(file.type));

    if (validFiles.length !== files.length) {
        alert('Only PDF, CSV, DOCX, and TXT files are allowed.');
    }

    setSelectedFiles(validFiles);
};

    const removeFile = (index) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
    };


    const [passwordData, setPasswordData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [warning, setWarning] = useState('');

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setPasswordData({
            ...passwordData,
            [name]: value
        });
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch('https://api.digitalaalim.in/user/me', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setPasswordData(prevData => ({
                        ...prevData,
                        name: data.name,
                        email: data.email
                    }));
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordShown(confirmPasswordShown => !confirmPasswordShown);
    };

    const updatePassword = async (event) => {
        const accessToken = localStorage.getItem('accessToken');
        event.preventDefault();
        setIsSettingLoading(true);

        if (passwordData.password !== passwordData.confirmPassword) {
            setWarning('Passwords do not match!');
            return;
        }
        setWarning('');

        try {
            const response = await fetch('https://api.digitalaalim.in/user/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    name: passwordData.name,
                    email: passwordData.email,
                    password: passwordData.password
                })
            });

            if (response.ok) {
                setIsSettingLoading(false);

                alert("Password Updated Successfully");
            } else {
                console.error('Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const renderContent = () => {
        switch (activeSection) {
            case 'chatbot1':
                return (
                    <div className="chatbot-update-container">
                        <h1>Deploy New Maktab Aalim</h1>
                        <p>Deploy a new Aalim bot with diverse information to enhance the capabilities of Digital
                            Aalim.</p>
                        <div className="update-form">
                            <div className="upload-section">
                                <label
                                    className="icon-input-label"
                                    style={{
                                        backgroundImage: iconPreview ? `url(${iconPreview})` : 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23000\' viewBox=\'0 0 24 24\'%3E%3Cpath d=\'M10 8H8v2H6v2h2v2h2v-2h2v-2h-2z\'/%3E%3Cpath d=\'M5 2C3.89 2 3 2.9 3 4v16c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V4c0-1.1-.89-2-2-2H5zm0 2h14v16H5V4z\'/%3E%3C/svg%3E")',
                                        backgroundSize: iconPreview ? 'cover' : '30%', // Smaller size for the default icon, full cover for images
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleIconChange}
                                        className="icon-input"
                                    />
                                </label>
                                {iconPreview && (
                                    <button onClick={handleCancelUpload} className="cancel-upload-btn">
                                        Cancel
                                    </button>
                                )}

                                {/* Instructions for image upload */}
                                <p className="upload-instructions">
                                    Please upload an image of size 200x200 pixels.
                                </p>
                            </div>


                            <input
                                type="text"
                                placeholder="Enter bot name"
                                value={botName}
                                onChange={handleBotNameChange}
                                className="bot-name-input"
                            />
                            <input
                                type="text"
                                placeholder="Enter link"
                                value={link}
                                onChange={handleDeployLinkChange}
                                className="link-input"
                            />
                            <textarea
                                type="text"
                                placeholder="Enter bot description"
                                value={botDescription}
                                onChange={(e) => setBotDescription(e.target.value)}
                                className="bot-description-input"
                                rows="5"
                            />
                            <textarea
                                value={instruction}
                                onChange={handleInstructionChange}
                                placeholder="Enter instruction"
                                className="input-field"
                                rows="3"
                                style={{width: '100%', marginTop: '10px'}}
                            />
                            <input
                                type="file"
                                multiple
                                onChange={handleUpdateFileChange}
                                className="file-input"
                            />
                            <ul className="file-list">
                                {selectedFiles.map((file, index) => (
                                    <li key={index}>
                                        {file.name}
                                        <button onClick={() => removeFile(index)} className="delete-btn">X</button>
                                    </li>
                                ))}
                            </ul>
                            {selectedFiles.length === 0 && (
                                <p className="error-message">Please upload at least one file to deploy the bot.</p>
                            )}
                            <div className="signup-action-container">
                                <button onClick={deployBot} className="signup-btn"
                                        disabled={isDeployLoading || selectedFiles.length === 0}>
                                    Deploy
                                </button>
                                {isDeployLoading && <div className="loader"></div>}
                            </div>
                        </div>
                    </div>
                );

            case 'feedback':
                return (
                    <div className="feedback-container">
                        <h1 className="title-feedback">Submit Your Feedback</h1>
                        <div className="feedback-form">
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Organization Name"
                                className="input-field"
                            />
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                className="input-field"
                            />
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Feedback"
                                className="input-field"
                                rows="5"
                            />
                            <div className="signup-action-container">
                                <button onClick={submitFeedback} className="signup-btn"
                                        disabled={isFeedbackLoading}> Submit Feedback
                                </button>
                                {isFeedbackLoading && <div className="loader"></div>}
                            </div>
                        </div>
                    </div>
                );


            case 'settings':
                return (
                    <div className="admin-settings-container">
                        <h1 className="title-feedback">Settings</h1>
                        <p className="description">Update Password</p>
                        <div className="setting-card">
                            <form onSubmit={updatePassword} className="update-password-form">
                                <div className="fixed-fields">
                                    <input type="text" name="name" placeholder="Name" value={passwordData.name}
                                           readOnly/>
                                    <input type="email" name="email" placeholder="Email" value={passwordData.email}
                                           readOnly/>
                                </div>
                                <div className="password-field-maktab">
                                    <input type={passwordShown ? 'text' : 'password'} name="password"
                                           placeholder="New Password" value={passwordData.password}
                                           onChange={handleInputChange}/>
                                    <i onClick={togglePasswordVisibility}
                                       style={{
                                           position: 'absolute',
                                           right: '15px',
                                           top: '49%',
                                           transform: 'translateY(-50%)'
                                       }}>
                                        {passwordShown ? <FaEye/> : <FaEyeSlash/>}
                                    </i>
                                </div>
                                <div className="password-field-maktab">
                                    <input type={confirmPasswordShown ? 'text' : 'password'} name="confirmPassword"
                                           placeholder="Confirm New Password" value={passwordData.confirmPassword}
                                           onChange={handleInputChange}/>
                                    <i onClick={toggleConfirmPasswordVisibility}
                                       style={{
                                           position: 'absolute',
                                           right: '15px',
                                           top: '49%',
                                           transform: 'translateY(-50%)'
                                       }}>
                                        {confirmPasswordShown ? <FaEye/> : <FaEyeSlash/>}
                                    </i>

                                </div>
                                {warning && <p className="warning">{warning}</p>}
                                <div className="signup-action-container">
                                    <button type="submit" className="signup-btn"
                                            disabled={isSettingLoading}> Update
                                    </button>
                                    {isSettingLoading && <div className="loader"></div>}
                                </div>
                            </form>
                        </div>

                    </div>

                );

            default:
                return (
                    <div>
                        <div className="heading">
                            <h1>Digital Aalim Maktab Management Dashboard</h1>
                            <p>In this section, you can view all created bots, as well as update or delete existing
                                ones.</p>
                        </div>
                        <div className="card-container">
                            <table className="bot-table">
                                <thead>
                                <tr>
                                    <th>Icon</th>
                                    <th>Bot Name</th>
                                    <th>Sect</th>
                                    <th>Description</th>
                                    <th>Approved</th>
                                    <th>Update Bot</th>
                                    <th>Delete Bot</th>
                                </tr>
                                </thead>


                                <tbody>
                                {bots.map(bot => (
                                    <tr key={bot.bot_id}>
                                        <td>

                                            <img
                                                src={bot.icon_path ? `https://api.digitalaalim.in/${bot.icon_path}` : 'default_icon.png'}
                                                alt="Bot Icon"
                                                title={`Bot Name: ${bot.bot_name}\nDescription: ${bot.description}`} /* Tooltip content */
                                                style={{width: '100px', height: '100px', borderRadius: '50%'}}
                                            />

                                        </td>
                                        <td>{bot.bot_name}</td>
                                        <td>{bot.sect}</td>
                                        <td>{bot.description}</td>

                                        <td>{bot.approved ? 'Yes' : 'No'}</td>
                                        <td>
                                            <div className="update-form">
                                                <input
                                                    type="text"
                                                    placeholder="Enter link"
                                                    value={linkInputs[bot.bot_id] || ''}
                                                    onChange={(e) => handleLinkChange(e, bot.bot_id)}
                                                    className="file-input"
                                                />
                                                <input
                                                    type="file"
                                                    multiple
                                                    onChange={(e) => handleFileChange(e, bot.bot_id)}
                                                    className="file-input"
                                                />
                                                <ul className="file-list">
                                                    {fileInputs[bot.bot_id] && fileInputs[bot.bot_id].map((file, index) => (
                                                        <li key={index}>
                                                            {file.name}
                                                            <button
                                                                onClick={() => handleFileDelete(bot.bot_id, index)}
                                                                className="delete-btn"
                                                            >
                                                                X
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <button
                                                onClick={() => updateBot(bot.bot_id)}
                                                className="signup-btn"
                                                disabled={isLoading[bot.bot_id]}
                                            >
                                                Update Bot
                                            </button>
                                            {isLoading[bot.bot_id] && <div className="loader"></div>}
                                        </td>
                                        <td>
                                            <button className="delete-button" onClick={() => deleteBot(bot.bot_id)}>
                                                <FaTrash/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                );

        }
    };


    return (
        <div className="dashboard-container">
            <aside className="sidebar">
                <h2>Menu</h2>
                <button onClick={() => setActiveSection('home')}
                        className={activeSection === 'home' ? 'active' : ''}>
                    Manage Chatbots
                </button>
                <button onClick={() => setActiveSection('chatbot1')}
                        className={activeSection === 'chatbot1' ? 'active' : ''}>
                    Deploy New Chatbots
                </button>
                <button onClick={() => setActiveSection('feedback')}
                        className={activeSection === 'feedback' ? 'active' : ''}>
                    Submit Feedbacks
                </button>
                <button onClick={() => setActiveSection('settings')}
                        className={activeSection === 'settings' ? 'active' : ''}>
                    Settings
                </button>
                <button onClick={handleLogout}>Logout</button>
            </aside>
            <main className="main-content">
                {renderContent()}
            </main>
        </div>
    );
}

export default Dashboard;
