import {Link, useNavigate} from 'react-router-dom';
import './Login.css';
import loginImage from '../Assets/newlogin.jpg';
// import { Link } from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';


function Login() {
    const [newPassword, setNewPassword] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [stage, setStage] = useState('login'); // 'login', 'requestOtp', 'resetPassword'

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill("")); // OTP as an array of 6 empty strings
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const [passwordShown, setPasswordShown] = useState(false);  // State for showing/hiding password
    const [newPasswordShown, setNewPasswordShown] = useState(false);  // State for showing/hiding new password in reset form


    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/Dashboard');
        }
    }, [isLoggedIn, navigate]);


    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false; // Ensure input is numeric
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Auto-focus to next input
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    // Modify handleSubmit to call initializeChatbot after a successful login
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch('https://api.digitalaalim.in/login/madrasah-admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Origin': 'https://chat.endevsolsai.com/'
                },
                body: new URLSearchParams({
                    'username': email,
                    'password': password
                })
            });

            if (!response.ok) {
                setIsLoading(false);
                throw new Error('Incorrect username or password');
            }

            const data = await response.json();
            localStorage.setItem('accessToken', data.access_token);

            // Call initializeChatbot here, make sure to pass navigate as an argument
            await initializeChatbot(navigate);
            setIsLoading(false);

            setIsLoggedIn(true); // Move this inside initializeChatbot after successful initialization
            //alert("Login Successful");

        } catch (error) {
            setErrorMessage("Login Failed: " + error.message);
        }
    };

    const initializeChatbot = async (navigate) => {
        try {
            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken) {
                throw new Error("No access token found. Please login or sign up.");
            }

            const initResponse = await fetch('https://api.digitalaalim.in/initialize_model/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Origin': 'https://chat.digitalaalim.in/'
                },
                // Assuming you eventually want to send the formData
                // body: formData,
            });

            if (!initResponse.ok) {
                if (initResponse.statusText === "Unauthorized") {
                    localStorage.removeItem('accessToken');
                    navigate('/');
                    throw new Error(`Session Expired. Login again`);
                } else {
                    throw new Error(`Failed to initialize the model: ${initResponse.statusText}`);
                }
            }

            //const responseData = await initResponse.json();
            //const successMessage = responseData.message;

            //alert(successMessage);

        } catch (error) {
            console.error('Error initializing the chatbot:', error.message);
            alert(`Chatbot initialization failed: ${error.message}`);
        }
    };


    const handleRequestOtp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.digitalaalim.in/password-reset/request?email=${email}`, {
                method: 'POST'
            });
            const data = await response.json();
            setIsLoading(false);
            if (response.ok) {
                alert("OTP sent to your email. Please verify to continue.");
                setStage('resetPassword'); // Move to the reset password stage
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            setErrorMessage(error.message);
            setIsLoading(false);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.digitalaalim.in/password-reset/verify?email=${email}&otp=${otp.join('')}&new_password=${newPassword}`, {
                method: 'POST'
            });
            const data = await response.json();
            setIsLoading(false);
            if (response.ok) {
                alert(data.message);
                setStage('login'); // Reset complete, back to login
                setEmail('');
                setOtp('');
                setNewPassword('');
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            setErrorMessage(error.message);
            setIsLoading(false);
        }
    };

    const displayForm = () => {
        switch (stage) {
            case 'requestOtp':
                return (
                    <form className="login-form" onSubmit={handleRequestOtp}>
                        <h1>Request OTP</h1>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="signup-action-container">
                            <button type="submit" className="signup-btn" disabled={isLoading}>Send OTP</button>
                            {isLoading && <div className="loader"></div>}
                        </div>
                    </form>
                );
            case 'resetPassword':
                return (
                    <form className="login-form" onSubmit={handlePasswordReset}>
                        <h1>Reset Password</h1>
                        <h2>Enter OTP</h2>
                        <div className="otp-container">
                            {otp.map((_, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    className="otp-input"
                                    maxLength="1"
                                    value={otp[index] || ""}
                                    onChange={e => handleOtpChange(e.target, index)}
                                />
                            ))}
                        </div>
                        <h2>Enter New Password</h2>
                        <div className="password-field">
                            <input
                                type={newPasswordShown ? 'text' : 'password'}
                                placeholder="New Password"
                                required
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <i onClick={toggleNewPasswordVisibility}
                               style={{position: 'absolute', right: '15px', top: '49%', transform: 'translateY(-50%)'}}>
                                {newPasswordShown ? <FaEye/> : <FaEyeSlash/>}
                            </i>
                        </div>
                        <div className="signup-action-container">
                            <button type="submit" className="signup-btn" disabled={isLoading}>Verify and Update</button>
                            {isLoading && <div className="loader"></div>}
                        </div>
                    </form>
                );
            default:
                return (
                    <form className="login-form" onSubmit={handleSubmit}>
                        <h1>Welcome to Digital Aalim Maktab Panel</h1>
                        <input
                            type="email"
                            placeholder="Email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="password-field">
                            <input
                                type={passwordShown ? 'text' : 'password'}
                                placeholder="Password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <i onClick={togglePasswordVisibility}
                               style={{position: 'absolute', right: '15px', top: '49%', transform: 'translateY(-50%)'}}>
                                {passwordShown ? <FaEye/> : <FaEyeSlash/>}
                            </i>
                        </div>
                        <p className="forgot-password">
                            Forgot password? <a href="#" onClick={() => setStage('requestOtp')}>Click here</a>
                        </p>
                        <div className="signup-action-container">
                            <button type="submit" className="signup-btn" disabled={isLoading}>Login</button>
                            {isLoading && <div className="loader"></div>}
                        </div>
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <p className="signup-message">
                            Don't have an account? <Link to="/signup">Sign up</Link>
                        </p>
                    </form>

                );
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-image-container">
                    <img src={loginImage} alt="Login" className="login-image"/>
                </div>
                {displayForm()}
            </div>
        </div>

    );
}

export default Login;
